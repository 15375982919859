import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import { AxisBottom, AxisRight } from '@visx/axis';
import { scaleLinear, scaleTime, } from '@visx/scale';
import { LinearGradient } from '@visx/gradient';
import { extent } from '@visx/vendor/d3-array';
import { curveLinear } from '@visx/curve';
import { AreaClosed, LinePath } from '@visx/shape';
import { GridColumns, GridRows } from '@visx/grid';
import { useTooltip, TooltipWithBounds } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { useCallback, useMemo, } from 'react';
import { format } from 'date-fns';
import { useAppSelector } from '@app/store/Hooks';
import { getLocale } from '@shared/lib/DateTime';
import { defineAreas } from './utils';
const GraphWidth = 824;
const GraphHeight = 294;
const CurveWidth = 748;
const CurveHeight = 246;
const CurveContainer = styled.div(props => ({
    position: 'relative',
    width: props.graphWidth,
    height: props.graphHeight,
}));
const TooltipStyled = styled(TooltipWithBounds, { shouldForwardProp: propName => propName !== 'isVisible' })(props => ({
    '&.visx-tooltip': {
        visibility: props.isVisible ? 'visible' : 'hidden',
        backgroundColor: props.theme.palette.text.primary,
        color: '#fff',
        pointerEvents: 'none',
        fontSize: 14,
        letterSpacing: '-0.084px',
        lineHeight: '20px',
    },
}));
const TooltipDate = styled.span(() => ({
    color: '#D6DCE0',
}));
const TooltipValue = styled.span();
const TooltipLine = styled.path(props => ({
    visibility: props.isVisible ? 'visible' : 'hidden',
    stroke: props.isValuePositive ? 'green' : '#E5484D',
    strokeDasharray: '4 4',
    transform: props.xCoord ? `translateX(${props.xCoord}px)` : '',
}));
const TooltipDotCircle = styled.circle(props => ({
    fill: 'transparent',
    stroke: props.isValuePositive ?
        props.theme.palette.success.main : props.theme.palette.error.main,
    strokeWidth: 2,
    visibility: props.isVisible ? 'visible' : 'hidden',
    transform: `translate(${props.x || 0}px, ${props.y || 0}px)`,
}));
const XAxisContainer = styled.g(() => ({
    transform: `translate(0, calc(${CurveHeight + 15}px))`,
    '& .ProfitCurve__XAxis text': {
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '-0.084px',
        fill: '#ABB4BB',
    },
}));
const YAxisContainer = styled.g(() => ({
    // 8px is "x" attribute value of tspan\svg elements inside axis
    // so there`re no ways to affect on it, only via styles
    transform: `translate(calc(${CurveWidth - 8 + 56}px), 0)`,
    '& .ProfitCurve__YAxis text': {
        x: 0,
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '-0.084px',
        fill: '#ABB4BB',
        dx: '100%',
        textAnchor: 'end',
    },
    '& .ProfitCurve__YAxis svg': {
        x: 0,
        width: 56,
    },
    '& .ProfitCurve__YAxis tspan': {
        x: 0,
    },
}));
const GridRowsStyled = styled(GridRows)(() => ({
    '& .visx-line': {
        stroke: '#EFF1F3',
        strokeDasharray: '4 4',
    },
}));
const GridColumnsStyled = styled(GridColumns)(() => ({
    '& .visx-line': {
        stroke: '#EFF1F3',
        strokeDasharray: '4 4',
    },
}));
const Svg = styled.svg(() => ({
    width: '100%',
    height: 'inherit',
    overflow: 'visible',
}));
const CurveSvgContainer = styled.svg();
const tooltipStyles = {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'inline-flex',
    gap: '6px',
    backgroundColor: 'rgba(17, 24, 28, 0.75)',
    padding: '4px 8px',
    borderRadius: '4px',
};
const MarginCurve = ({ data, }) => {
    var _a;
    const theme = useTheme();
    const { language } = useAppSelector((state) => state.app);
    const { maxY, minY, curveDots, } = useMemo(() => {
        const result = data.reduce((acc, val, index) => {
            const key = `${new Date(val[0]).getFullYear()}-${new Date(val[0]).getMonth()}-${new Date(val[0]).getDate()}`;
            return ({
                minY: Math.min(acc.minY, val[1]),
                maxY: Math.max(acc.maxY, val[1]),
                curveDots: Object.assign(Object.assign({}, acc.curveDots), { [key]: index }),
            });
        }, {
            maxY: -Infinity,
            minY: +Infinity,
            curveDots: {},
        });
        return result;
    }, [data]);
    const { hideTooltip, showTooltip, tooltipLeft, tooltipTop, tooltipOpen, tooltipData, } = useTooltip({});
    const getX = (pointCoords) => pointCoords === null || pointCoords === void 0 ? void 0 : pointCoords[0];
    const getY = (pointCoords) => pointCoords[1];
    const scaleX = useMemo(() => scaleTime({
        domain: extent(data, getX),
        range: [0, CurveWidth],
        nice: false,
    }), [data]);
    const scaleY = useMemo(() => scaleLinear({
        domain: extent(data, getY),
        range: [CurveHeight, 0],
        nice: true,
    }), [data]);
    const getGradientX = (datum) => datum[0];
    const getGradientY = (datum) => datum[1];
    const { gradientAreas, gradientXScale, gradientYScale, } = useMemo(() => {
        var _a;
        const gradientPoints = defineAreas(data);
        const allPoints = ((_a = Object.keys(gradientPoints)) === null || _a === void 0 ? void 0 : _a.length) > 1 ?
            Object.keys(gradientPoints)
                .reduce((acc, key) => {
                if (key !== 'areasCount') {
                    return [
                        ...acc,
                        ...(gradientPoints[key] || []),
                    ];
                }
                return acc;
            }, []) : [];
        const xScale = scaleLinear({
            domain: extent(allPoints, getGradientX),
            range: [0, CurveWidth],
            nice: false,
            reverse: true,
        });
        const yScale = scaleLinear({
            domain: extent(allPoints, getGradientY),
            range: [CurveHeight, 0],
            nice: true,
        });
        return {
            gradientAreas: gradientPoints,
            gradientXScale: xScale,
            gradientYScale: yScale,
        };
    }, [data]);
    // const areasScale = scaleBand([0, 2.5, 7.5], [0, CurveWidth])
    // const mappedData = useMemo(() => defineAreas(data), [data])
    const defineGradient = (key) => {
        if (key.includes('success')) {
            return '#margin-success-background';
        }
        if (key.includes('warning')) {
            return '#margin-warning-background';
        }
        return '#margin-error-background';
    };
    const scaleYFormat = (date) => `${date} %`;
    const scaleXFormat = (date) => format(date, 'dd MMM', { locale: getLocale(language || 'en') });
    const { yPositivePoint, yWarningPoint, } = useMemo(() => {
        const rangePixelValues = scaleY.range();
        const isRangeValid = rangePixelValues !== null && (rangePixelValues === null || rangePixelValues === void 0 ? void 0 : rangePixelValues.length) === 2 &&
            typeof rangePixelValues[0] === 'number' && typeof rangePixelValues[1] === 'number';
        if (isRangeValid) {
            const pixelToValRatio = Math.abs(rangePixelValues[1] - rangePixelValues[0]) /
                (Math.abs(minY - maxY)); // px / val points
            // if minY would be zero - it means that (0,0) is equal to 0px
            // if not - we go up to the zero point
            // (ex: if minY - 49px that means that we should go up to 49px)
            return {
                yPositivePoint: (1 - ((pixelToValRatio * Math.abs(minY - 35)) /
                    Math.abs(rangePixelValues[1] - rangePixelValues[0]))) * 100,
                yWarningPoint: (1 - ((pixelToValRatio * Math.abs(minY - 60)) /
                    Math.abs(rangePixelValues[1] - rangePixelValues[0]))) * 100,
            };
        }
        return {
            yPositivePoint: 0,
            yWarningPoint: 0,
        };
    }, [scaleY, maxY, minY]);
    const handleTooltipPosition = useCallback((event) => {
        const { x } = localPoint(event) || { x: 0 };
        const x0 = scaleX.invert(x);
        const key = `${new Date(x0).getFullYear()}-${new Date(x0).getMonth()}-${new Date(x0).getDate()}`;
        const index = curveDots[key];
        const d0 = data[index > 0 ? index - 1 : 0];
        const d1 = data[index];
        let d = d0;
        if (d1 && getX(d1)) {
            d =
                x0.valueOf() - getX(d0).valueOf() <
                    getX(d1).valueOf() - x0.valueOf() ?
                    d1 :
                    d0;
        }
        showTooltip({
            tooltipData: {
                date: `${(d === null || d === void 0 ? void 0 : d.length) ? format(new Date(d[0]), 'do MMMM, hh:mm', { locale: getLocale(language || 'en') }) : ''}`,
                value: d ? `${Math.floor(getY(d))} %` : '0 %',
                xCoord: d ? scaleX(getX(d)) : 0,
                yCoord: d ? scaleY(getY(d)) : 0,
                isPositive: d[1] >= 0,
            },
            tooltipLeft: d ? scaleX(getX(d)) + 3 : 0,
            tooltipTop: d ? scaleY(getY(d)) + 3 : 0,
        });
    }, [showTooltip, scaleX, scaleY, data, curveDots, language]);
    return (_jsxs(CurveContainer, { graphWidth: GraphWidth, graphHeight: GraphHeight, children: [_jsxs(Svg, { children: [_jsxs(CurveSvgContainer, { onTouchStart: handleTooltipPosition, onTouchMove: handleTooltipPosition, onMouseMove: handleTooltipPosition, onMouseLeave: hideTooltip, children: [_jsx(GridColumnsStyled, { scale: scaleX, height: CurveHeight, numTicks: 4 }), _jsx(GridRowsStyled, { scale: scaleY, width: CurveWidth, numTicks: 7 }), _jsx(LinearGradient, { id: 'margin-linear-error-gradient', fromOffset: `${yWarningPoint}%`, toOffset: `${yWarningPoint}%`, from: theme.palette.error.main, to: 'transparent' }), _jsx(LinearGradient, { id: 'margin-linear-warning-gradient', from: theme.palette.warning.main, fromOffset: `${yPositivePoint}%`, toOffset: `${yPositivePoint}%`, to: theme.palette.success.main }), _jsx(LinearGradient, { id: 'margin-success-background', fromOffset: '0%', from: theme.palette.success.main, toOffset: 1, to: '#fff', toOpacity: 0.37 }), _jsx(LinearGradient, { id: 'margin-error-background', fromOffset: '0%', from: theme.palette.error.main, fromOpacity: 0.8, toOffset: 1, to: '#fff', toOpacity: 0.31 }), _jsx(LinearGradient, { id: 'margin-warning-background', fromOffset: '0%', from: theme.palette.warning.main, toOffset: 1, to: '#fff', toOpacity: 0.51 }), _jsx(LinePath, { x: (d) => scaleX(getX(d)), y: (d) => scaleY(getY(d)), curve: curveLinear, data: data, strokeWidth: 2, stroke: 'url(#margin-linear-warning-gradient)' }), _jsx(LinePath, { x: (d) => scaleX(getX(d)), y: (d) => scaleY(getY(d)), curve: curveLinear, data: data, strokeWidth: 2, stroke: 'url(#margin-linear-error-gradient)' }), ((_a = Object.keys(gradientAreas)) === null || _a === void 0 ? void 0 : _a.length) && (Object.keys(gradientAreas).reverse().map((key) => {
                                if (key !== 'areasCount') {
                                    return (_jsx(AreaClosed, { data: gradientAreas[key] ||
                                            [], yScale: gradientYScale, x: (d) => gradientXScale(getGradientX(d)), y: (d) => gradientYScale(getGradientY(d)), fill: `url(${defineGradient(key)})`, strokeWidth: 1 }, key));
                                }
                                return null;
                            })), _jsx(TooltipLine, { isVisible: tooltipOpen, isValuePositive: Boolean(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.isPositive), xCoord: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, d: `M0 0 L0 ${CurveHeight}` }), _jsx(TooltipDotCircle, { isVisible: tooltipOpen, isValuePositive: Boolean(tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.isPositive), x: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.xCoord, y: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.yCoord, r: 3 })] }), _jsx(YAxisContainer, { children: _jsx(AxisRight, { axisClassName: 'ProfitCurve__YAxis', scale: scaleY, tickFormat: (value) => scaleYFormat(value), numTicks: 7, hideAxisLine: true, hideTicks: true }) }), _jsx(XAxisContainer, { children: _jsx(AxisBottom, { axisClassName: 'ProfitCurve__XAxis', scale: scaleX, tickFormat: (date) => scaleXFormat(date), numTicks: 5, hideAxisLine: true, hideTicks: true }) })] }), _jsxs(TooltipStyled, { isVisible: tooltipOpen, style: Object.assign(Object.assign({}, tooltipStyles), { transform: `translate(${tooltipLeft || 0}px, ${tooltipTop || 0}px)` }), children: [_jsx(TooltipDate, { children: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.date }), _jsx(TooltipValue, { children: tooltipData === null || tooltipData === void 0 ? void 0 : tooltipData.value })] })] }));
};
export default MarginCurve;
